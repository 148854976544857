import { PageBody, PageCard } from '../Pages/PageContainers'
import * as PageStyles from '../page-styles.module.css'
import { Button, TextField } from '@material-ui/core'
import { FormEvent, useRef } from 'react'
import { useBottleRegistrationContext } from '../registrationContext'
import { getBottleQueryPath } from '../formatters'
import { bottleIDRegex, getSampleRegistrationBaseURL } from '../constants'
import { useIsLoggedIn } from 'hooks/useIsLoggedIn'

type TProps = {
  // onCancel: () => void
  onSubmit: (bottleIdentifier: string) => void
}

export const BottleIdentifierInput = (props: TProps) => {
  const bottleIdentifierInputRef = useRef<HTMLInputElement>();
  const bottleRegistrationContext = useBottleRegistrationContext()

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const bottleIdentifierFromURL = bottleIDRegex.exec(bottleIdentifierInputRef.current.value)?.groups?.reducedBottleID;

    bottleRegistrationContext.updateFormStateValue(
      'bottleIdentifier',
      bottleIdentifierFromURL ?? bottleIdentifierInputRef.current.value
    )

    const submitDataIsValid = bottleRegistrationContext.validateFormValues()
    if (!submitDataIsValid) return

    if (window.history.pushState) {
      window.history.pushState(
        null,
        null,
        `${getSampleRegistrationBaseURL()}${getBottleQueryPath({
          bottleID: bottleIdentifierFromURL ?? bottleIdentifierInputRef.current.value
        })}`
      )
    }

    props.onSubmit(bottleIdentifierFromURL ?? bottleIdentifierInputRef.current.value)
  }

  const isLoggedIn = useIsLoggedIn()

  console.log(bottleIdentifierInputRef.current?.value)
  return (
    <PageCard>
      <form
        className={`${PageStyles.EntryScreen} ${isLoggedIn.loggedIn ? PageStyles.LoggedIn : ''
          }`}
        onSubmit={submit}
      >
        <div>
          <h4 className={PageStyles.MainTitle}>Locate Your Bottle</h4>
          <sub className={PageStyles.SubTitle}>
            <span className={PageStyles.BlockSpan}>
              Enter the identifier beneath the QR code image.
            </span>
          </sub>

          <div className={PageStyles.DetailEntryContainer}>
            <TextField
              error={
                !bottleRegistrationContext.state.bottleIdentifier.isValid()
              }
              helperText={
                !bottleRegistrationContext.state.bottleIdentifier.isValid() &&
                bottleRegistrationContext.state.bottleIdentifier.getValidationMessage()
              }
              inputRef={bottleIdentifierInputRef}
              autoFocus
              required
              id="bottle-identifier-input"
              label="Bottle Identifier"
              variant="outlined"
            />
          </div>
        </div>
        <div>
          <section className={PageStyles.DetailSection}>
            <p className={PageStyles.FinePrintParagraph}>
              Locate your bottle by entering the identifier beneath the QR code
              or by scanning the provided QR code and navigating to the link.
            </p>
            <p className={PageStyles.FinePrintParagraph}>
              This will allow you to supply sample information to the laboratory
              for tracibility and will be retained for your future review. This
              information will always be accessible through this identifier and
              QR code.
            </p>
          </section>
          <div className={PageStyles.ButtonContainer}>
            <Button
              data-cancel
              variant="contained"
              color="primary"
              onClick={() =>
                bottleRegistrationContext.updateFormStateValue(
                  'bottleIdentifier',
                  ''
                )
              }
              className={`${PageStyles.Button} ${PageStyles.Left}`}
            >
              Clear
            </Button>

            <Button
              data-accept
              variant="contained"
              color="secondary"
              type="submit"
              className={`${PageStyles.Button} ${PageStyles.Right}`}
            >
              Locate Bottle
            </Button>
          </div>
        </div>
      </form>
    </PageCard>
  )
}
