import { InvoiceStatus } from 'components/FilterAccordion/types'
import { TCondition } from 'components/filters/SmartFilter/SmartFilterTypes'
import { Condition } from 'services/lookup-service'
import { SalesInvoiceStatus } from 'utils/getSalesInvoiceStatusDescription'
import { LinkLevel, LinkStatus, BottleStatusCode, SampleStatus } from './custom'

export type Product = {
  productID: number
  labels: boolean
  productName: string
  imageFileName: string
  productDescription: string
  productPricing: ProductPricing[]
  productGroups: ProductGroup[]
}

export type ProductSansPricingCustomerID = Omit<Product, 'productPricing'> & {
  productPricing: ProductPricingSansCustomerID[]
}

export type ProductPricingSansCustomerID = {
  unitPrice: number
  currencyCode: string
  minimumQuantity: number
  maximumQuantity: number | null
  pricingID: number | null
}

export type ProductPricing = {
  customerID: number | null
  unitPrice: number
  currencyCode: string
  minimumQuantity: number
  maximumQuantity: number | null
  pricingID: number | null
}

export type ProductGroup = {
  groupID: number
  groupDescription: string
  customerIDs: number[]
}

export type TProductEditing = Product & {
  firstGroupID: number
  firstGroupDescription: string
}

export type TProductPricingEditing = ProductPricing & {}

export type TCustomerProductPricing = {
  customerID: number
  products: Product[]
}

export type TCustomerProductGroup = {
  customerID: number
  productGroups: ProductGroup[]
}

export type TCustomerProductGroupEditing = {
  customerID: number
  productGroupIDs: number[]
}

export type TCustomerTestGroupPricing = {
  customerID: number
  customerName: string
  pricing: TCustomerTestGroupPricingDetails[]
}

export type TCustomerTestGroupPricingDetails = {
  pricePerUnitUSD: number
  testGroup: TTestGroupDetails
}

export type TTestGroupDetails = {
  testGroupID: number
  testGroup: string
  waterScreen: boolean
  reportType: number
  active: number
  updatedDate: string | null
  updatedBy: number | null
  tests: TTestCodeDetails[]
}

export type TTestCodeDetails = {
  testID: number
  testCode: string
  testDesc: string
  units: string
  outside: boolean
  sampleAmount: string
  detailedDesc: string
}

export type TAPIPicklistItem = {
  listValue: string
  listDesc: string
}

export type TAPILubePickListItem = {
  pickListValue: string
  lubemfr: string
}

export type TAPIUser = {
  firstName: string
  lastName: string
  loginID: string
  readOnly: boolean
  cCID: number
}

export type TAPIProfileDivision = {
  iD: number
  name: string
  customers: Array<{
    custID: number
  }>
}

export type TFinancialAlertsQuery = {
  customerIDs: number[]
  plantIDs?: number[]
}

export type TNoSalesOrderQuery = {
  customerIDs: number[]
}

export type GetCustomersQuery = {
  customerID?: number[]
  customerName?: string[]
  active?: boolean
  locationID?: number[]
}

export type GetCustomerByEncryptedBottleIdentifierQuery = {
  encryptedBottleIdentifier: string
}

export type GetCustomersResponse = GetCustomerDTO[]

export type CustomerResponseDTO = {
  customerID: number
  customerName: string
  active: boolean
  customerNotes: string
}

export type GetCustomerPrimaryContactByEncryptedBottleIdentifierQuery = {
  encryptedBottleIdentifier: string
}

export type GetCustomerPrimaryContact = {
  customerID: number
}

export type GetContactsQuery = {
  customerIDs?: number[]
  plantIDs?: number[]
  primaryContact?: boolean
}

export type GetContactsByEncryptedBottleIdentifierQuery = {
  encryptedBottleIdentifier: string
}

export type GetContactsResponse = ContactDTO[]

export type ContactDTO = {
  contactId: number
  lastName: string
  firstName: string
  title?: string
  phone?: string
  email?: string
  report?: boolean
  summaryReport?: boolean
  datafileReport?: boolean
  address: TContactAddress
}

export type TGetPlantsByEncryptedBottleQuery = {
  encryptedBottleIdentifier: string
}

export type GetBillingPlantsQuery = {
  customerIDs?: number[]
  plantIDs?: number[]
  labIDs?: number[]
  linkIDs?: string[]
  financialIDs?: string[]
}

export type TPlantQuery = {
  customerIDs?: number[]
  plantIDs?: number[]
  labIDs?: number[]
  linkIDs?: string[]
  financialIDs?: string[]
}

export type TIssueType =
  | 'ExpiringSoon'
  | 'DueSoon'
  | 'PastDue'
  | 'OverBudgetSoon'
  | 'OverBudget'

type TSalesIssue = {
  amount: number
  number: string
  reason: string
  reasonDescription: string
  issue: TIssueType
  dueDate?: Date
}

export type TSalesInvoiceIssue = {
  remainingBalance: number
} & TSalesIssue

export type TSalesOrderIssue = {
  nextMonthProjectedSpending: TMonthlySpending
  availableBalance: number
} & TSalesIssue

export type TFinancialAlert = {
  financialID: string
  customerID: number
  plantID?: number
  salesInvoiceIssues: TSalesInvoiceIssue[]
  salesOrderIssues: TSalesOrderIssue[]
}

export enum SampleFinancialStatus {
  Unprocessed = 1,
  ProcessingError = 2,
  MultipleSalesOrders = 3,
  NoSalesOrder = 4,
  Linked = 5,
}

export enum SampleLaboratoryStatus {
  Entered = 1,
  Received,
  InProcess,
  LaboratoryHold,
  TestingComplete,
  QualityHold,
  QualityReviewed,
  Distributed,
}

export type TNoSalesOrderSample = {
  id: number,
  bottleID: string,
  labID: number,
  samplePointID: number,
  machineHours?: number,
  lubeHours?: number,
  receivedDate?: Date,
  sampleDate?: Date,
  oilChangedAt?: Date,
  financialStatusID: SampleFinancialStatus,
  laboratoryStatusID: SampleLaboratoryStatus,
  lubricantID: number,
  coordinateID?: number,
  rush: boolean,
  waterSoluble?: boolean,
  newOil?: boolean
}

export type TBillingCustomer = {
  billToContact: TBillingContact
  financialID: string
  limsPlantIDs: number[]
  limsCustomerIDs: number[]
  inactive: boolean
  email: string
  shipToContact: TBillingContact
}

export type TBillingContact = {
  address: TContactAddress
} & TPerson

export type TPerson = {
  firstName: string
  lastName: string
  suffix: string
  prefix: string
  title: string
  email: string
  middleInitial: string
  name: string
}

export type TContactAddress = {
  address1: string
  address2?: string
  city: string
  country: string
  salesTaxCode?: string
  state: string
  zip: string
}

export type TShipmentInformationQuery = {
  customerIDs: number[]
  plantNumbers: number[]
  orderByList: string[]
  page: number
  pageSize: number
  orderList: string[]
  statuses: string[]
  orderIDs: number
  trackingNumber: string
  from: Date | string
  to: Date | string
}

export type TInvoiceQuery = {
  customerIDs: number[]
  divisionIDs: number[]
  invoiceNumbers: string[]
  customerPONumber: string
  createdStartDate: Date | string
  createdEndDate: Date | string
  statuses: InvoiceStatus[]
}

export type TInvoiceXAPIQuery = {
  invoiceNumber: string | number
}

export type TReceiptQuery = {
  receiptNumber: string
  invoiceNumber: string
}

export type TFileDownloadResponse = {
  statusCode: number
  buffer: ArrayBuffer
}

export type TShipmentDetail = {
  orderDetailId: number
  product: string
  quantity: number
  invoiceNo: number
  labels: string
}

export type TShipmentStatus = 'INC' | 'CNX' | 'SHP' | 'PEN'

export type TShipmentInformation = {
  orderDetails?: TShipmentDetail[]
  shippingMethod: string
  orderId: number
  custId: number
  plantNo: number
  orderDate: Date
  purchaseOrderNumber: string
  shipCompany: string
  shipFirstName: string
  shipLastName: string
  shipAddress: string
  shipCity: string
  shipStateOrProvince: string
  shipPostalCode: string
  shipCountry: string
  shipPhoneNumber: string
  shipDate: Date
  freightCharge: number
  salesTaxRate: number
  trackingNumber: string
  notes: string
  shipFax: string
  shipEmail: string
  specialInstructions: string
  status: TShipmentStatus
}

export type TPagedShipmentInformation = {
  page: number
  pageSize: number
  count: number
  data: TShipmentInformation[]
}

export type TShipmentInformationWithTime = {
  lastUpdated: Date
  shipmentInformation?: TShipmentInformation
}

export type TAPIPlantContact = {
  plantID: number
  address1: string
  address2: string
  address3: string
  zip: string
  city: string
  state: string
  email: string
}

export enum LabelType {
  Avery,
  Dymo,
}

export enum PageBreakType {
  None,
  Route,
  Plant,
}

export type PrintLabelQuery = {
  labelType: LabelType
  numberOfLabels: number
  skip: number
  pageBreak: PageBreakType
  sampleDate: Date
  onlyCurrentMonth: boolean
  omitDate: boolean
  pointIDs: number[]
}

export type TInvoicePaymentStatus = {
  invoiceNumber: string
  success: boolean
  errorMessage: string
}

export type TSupplyOrderItemOrder = {
  quantity: number
  productID: number
  instructions?: string
}

export type TSupplyOrderFree = {
  customerID: number
  plantNo: number
  items: TSupplyOrderItemOrder[]
  shipmentEmail: string
  firstName: string
  lastName?: string
  shippingAddress: TContactAddress
  customerSupplyNote: string
}

export type TSupplyOrderPurchaseOrder = TSupplyOrderFree & TPurchaseOrder
export type TSupplyOrderCreditCard = TSupplyOrderFree & TCreditCardPaymentDetail

export type TInvoicesPayment = {
  financialID: string
  paymentMethodID: string
  invoiceNumbers: string[]
  amount: number
  email?: string
}

export type TSupplyOrderResponse = {
  orderID: number
}

export type TPurchaseOrder = {
  amount?: number
  customerPONote: string
  customerPONumber: string
  startDate?: Date
  expirationDate?: Date
  document: string
} & Omit<TBillingInformation, 'billingEmail'>

export type TCreditCardPaymentDetail = {
  paymentMethodID: string
} & TBillingInformation

export type TBillingInformation = {
  billingAddress: TContactAddress
  financialID: string
  billingEmail: string
}

export type TSuppliesPayment = {
  financialID: string
  customerID: number
  paymentMethodID: string
  items: TSupplyPaymentItem[]
  amount: number
  email?: string
  billingAddress: TContactAddress
}

export type TSupplyPaymentItem = {
  productID: number
  quantity: number
}

export type TSupplyOrderItem = {
  //index: number
  productID: number
  productDescription: string
  productName: string
  productPricing: ProductPricing[]
  selectedProductPrice: ProductPricing
  quantity: number
}

export type TAPITestGroup = {
  testGroupID: number
  testGroupName: string
  testGroupDefs: TAPITestGroupDef[]
}

export type TAPICorrectiveAction = {
  cADate: Date
  cANote: string
  listValue: string
  listDesc: string
  cACode: string
  workOrderNumber: string
  newOil: string
}

export type TAPIFeedback = {
  feedback: string
}

export type TAPILubricant = {
  lubeTypeID: number
  lubricantName: string
  lubeMFG: string
  v40: number
  fluidType: string
}

export type TAPILubricantManufacturer = {
  lubemfr: string
  pickListValue: string
}

export type TAPILubricantList = {
  lubricants: TAPILubricant[]
}
export type TAPIMachine = {
  pointID: number
  plant?: TAPIPlant
  machineName: string
  machType: string
  machMFG: string
  modelName: string
  machNo: string
  custEquID: string
  lubricant: TAPILubricant
  route?: TAPIRoute
  sumpCap?: number
  machSchedules?: TAPIMachineSchedule[]
}

export type TAPIMachineException = {
  divisionName?: string
  customerName: string
  plantName: string
  machineNumber: number
  custEquID: string
  machineName: string
  lastSampleDate: Date
  labID: number
  tests: string
  testPackages: string
  lubricant: string
  wearMetalCount: number
  ferrousCount: number
  particleCountCount: number
  waterCount: number
  viscosityCount: number
  degradationCount: number
  otherCount: number
}

export type TAPIPlant = {
  plantID: number
  plantName: string
  plantNo: number
  financialID: string
  machines: Array<TAPIMachine>
  customer?: TAPICustomer
}

export interface TAPIRoute {
  routeID: number
  routeNo: number
  name: string
  custID?: string
  customer?: TAPICustomer
}

export type TAPICustomer = {
  custID: number
  companyID?: number
  custName: string
  dBLogo: string
  routes: Array<TAPIRoute>
  plants: Array<TAPIPlant>
  division?: TAPIProfileDivision
  uPSSI: boolean
  industryCode: string
}

export type TAPICGProblem = {
  testID: number
  problem: string
}

export interface TAPISample {
  batchID: number
  labID: number
  lubCond: TCondition
  machCond: TCondition
  // cuCorrAct?: string
  // latestFeedback?: string
  machine: TAPIMachine
  reportDate: Date
  reportType: number
  reviewed: boolean
  rptNotes: string
  sampleDate: Date
  sampleNo: number
  problems: string
  cGProblem: TAPICGProblem[]
  sampleStatus: string
  latestFeedback: TAPIFeedback
  cuCorrAct?: TAPICorrectiveAction
  custReviewDte?: Date
  testGroup?: TAPITestGroup
}

export interface TAPITestCode {
  testCodeName: string
}

export interface TAPITestGroupDef {
  testCode: TAPITestCode
}

export interface TAPITestScheduleStatus {
  month: number
  year: number
  scheduled: number
  tested: number
  machCond: Condition
  lubCond: Condition
}

export interface TAPISampleComplianceRecord extends TAPISample {
  condition: Condition
  counts: TAPITestScheduleStatus[]
}

export interface TAPIDelinquentSample extends TAPISample {
  daysSinceLastSample: number
  lastSampleDate: Date
}

export type TAPIScheduledSample = TAPISample & TAPIMachineSchedule

export interface TAPIIssueSample {
  batchID: number
  cGProblem?: string
  custEquID: string
  custID: number
  custName: string
  holdDate?: Date
  holdNotes?: string
  holdReason?: string
  labID: number
  lubeCondition: number
  machName: string
  machNo: number
  machType: number
  machineCondition: number
  plantID: number
  plantName: string
  problems: string
  receivedDate: Date
  reportType: number
  routeID: number
  routeName: string
  routeNo: string
  sampleDate: Date
  sampleStatus: string
  testPackageList: string[]
}

export type TAPIConditionSet = {
  machine: TAPIMachine
  currentSample: TAPISample
  previousSample: TAPISample
}

export type TAPIConditionSummary = {
  plant: TAPIPlant
  samples: number
  machineNormal: number
  machineMarginal: number
  machineCritical: number
  machineUnknown: number
  lubeNormal: number
  lubeMarginal: number
  lubeCritical: number
  lubeUnknown: number
}

export type TExceptionSet = {
  plant: TAPIPlant
  totalSamples: number
  totalAlarmedSamples: number
  totalAlarmedMachines
  ferrousWear: number
  wearMetals: number
  particleCount: number
  water: number
  viscosity: number
  degradation: number
  other: number
}

export type TPdf = {
  fileName: string
  buffer: ArrayBuffer | null
}

export type TAPIMachineSchedule = {
  testGroup?: TAPITestGroup
  mSID?: number
  pointID: number | ''
  machine?: TAPIMachine
  testGroupID: number
  allMonths: boolean
  jan: boolean
  feb: boolean
  mar: boolean
  apr: boolean
  may: boolean
  jun: boolean
  jul: boolean
  aug: boolean
  sep: boolean
  oct: boolean
  nov: boolean
  dec: boolean
}

export type TSampleIssues = {
  customerID: number
  plantName: string
  numberOfIssues: number
  issues: string[]
}

export type TSalesInvoiceItem = {
  amount: number
  customerPONumber: string
  amountDue: number
  date: Date
  dueDate: Date
  status: SalesInvoiceStatus
  invoiceNumber: string
  invoicePdfUrl: string
  receiptPdfUrl: string
  customerIDs: number[]
  customerName: string
  divisionID: number
  divisionName: string
}

export type TSalesInvoice = {
  currentUnpaid: number
  isPastDue: boolean
  invoices: TSalesInvoiceItem[]
  isDivision: boolean
  isMultiCustomer: boolean
}

export type TOrder = {
  customerIDs: number[]
  salesOrderNumber: string
  purchaseOrderNumber: string
  status: string
  customers: string[]
  plants: string[]
  expirationDate: Date
  availableBalance: number
  initialBalance: number
  estimatedRemainingMonths: number
}

export type TSalesOrders = {
  currentBalance: number
  maxEstimatedRemainingMonths: number
  salesOrders: TOrder[]
}

export type TMachineGroup = {
  machineType: string
  amount: number
  percentBudgetSpend: number
  percentOfTotal: number
}

export type TPurchaseOrderInformation = {
  created: Date
  expirationDate: Date
  amount: number
  amountDue: number
  poNumber: string
  spent: number
  orderType: OrderType
}

export type TSpending = {
  budget: number
  amountSpent: number
  percentBudgetSpent: number
  spendingByMachineType: TMachineGroup[]
}

export type TMonthlySpending = {
  month: Date
} & TSpending

export type TInvoiceInformation = {
  salesInvoiceID: string
  amount: number
  amountDue: number
  date: Date
  dueDate: Date
  salesTaxAmount: number
  termsDescription: string
  isPaid: boolean
  invoiceNumber: string
  salesOrderNumber: string
}

export type TSpendingEntity = {
  id: number
  name: string
  purchaseOrders: TPurchaseOrderInformation[]
  financialID: string
  cumulativeSpending: TSpending
  currentMonthsSpending: TSpending
  averageMonthlySpending: TSpending
  historicalSpending: TMonthlySpending[]
  projectedSpending: TMonthlySpending[]
  children: TSpendingEntity[]
  // totalAmountDue: number
  // totalAmountPastDue: number
  invoices: TInvoiceInformation[]
  representativeOrderType: OrderType
}

export enum OrderType {
  DollarBalance = 'DollarBalance',
  Sample = 'Sample',
  FreeIssue = 'FreeIssue',
  PrePaid = 'PrePaid',
  Blanket = 'Blanket',
  Unknown = 'Unknown',
}

export type TAccumulatedSpending = {
  withinBudgetSpent: number
  overBudgetSpent: number
  index: number
  dateStart: Date
  dateEnd: Date
  label: string
  budget: number
}

export type TGetSpendingQuery = {
  customerIDs: number[]
  plantIDs: number[]
  pointIDs: number[]
  startDate?: string
  endDate?: string
}

export type ProblemDetails = {
  type?: string
  title?: string
  status?: number
  detail?: string
  instance?: string
  extensions?: Map<string, any>

  Type?: string
  Title?: string
  Status?: number
  Detail?: string
  Instance?: string
  Extensions?: Map<string, any>
}

// SECTION Bottle
/**Query for a bottle's status via its identifier. Authentication is required. */
export type GetBottleStatusQuery = {
  identifier: string
}

/**Query for a bottle's status via its encrypted identifier. */
export type GetEncryptedBottleStatusQuery = {
  encryptedIdentifier: string
}

/**Query for bottles via various information. Authentication is required. */
export type GetBottlesQuery = {
  bottleIdentifiers: string[]
  bottleStatus?: number
  bottleStatusMaximum?: number
  createdAt?: string //Date - ISO
}

/** Query for bottles via encrypted bottle identifiers. */
export type GetEncryptedBottlesQuery = {
  encryptedIdentifiers: string[]
}

/**Patch bottle information by matching on identifier. Null or undefined values are ignored. Authentication is required. */
export type PatchBottleQuery = {
  bottles: PatchBottleDTO[]
}

export type PatchBottleDTO = {
  identifier: string
  userID?: number
  status?: number
  companyID?: number
  customerID?: number
}

/**Patch bottle information by matching on the encrypted identifier. Null or undefined values are ignored. */
export type PatchEncryptedBottleQuery = {
  bottles: PatchEncryptedBottleDTO[]
}

export type PatchEncryptedBottleDTO = {
  encryptedIdentifier: string
  userID?: number
  status?: number
  companyID?: number
  customerID?: number
}

export type BottleStatusDetails = {
  customerID?: number
  status: BottleStatusCode
}

export type GetBottlesBaseResponseDTO = {
  createdAt: string //Date
  status: BottleStatusCode
  userId?: number
  companyID?: number
  customerID?: number
  companyName: string
}

export type GetBottlesResponse = GetBottlesResponseDTO[]
export type GetBottlesResponseDTO = GetBottlesBaseResponseDTO & {
  identifier: string
}

export type GetEncryptedBottlesResponse = GetEncryptedBottlesResponseDTO[]
export type GetEncryptedBottlesResponseDTO = GetBottlesBaseResponseDTO & {
  encryptedIdentifier: string
}

export type PatchBottlesResponse = PatchBottlesResponseDTO[]
export type PatchBottlesResponseDTO = GetBottlesResponseDTO

export type PatchEncryptedBottlesResponse = PatchEncryptedBottlesResponseDTO[]

export type PatchEncryptedBottlesResponseDTO =
  GetEncryptedBottlesResponseDTO & {
    customerID: number
  }
// !SECTION Bottle

// SECTION Plant
export type PostPlantsByEncryptedBottleQuery = {
  plants: PostPlantsByEncryptedBottleDTO[]
}

export type PostPlantsByEncryptedBottleDTO = {
  name: string
  locationID: number // 1 or 2.
  encryptedBottleIdentifier: string
  number?: number
  plantShippingContactID?: number
  primaryPlantContactID?: number
  plantCustomerContactID?: number
  industryCode?: string
  distributeAbnormalReportsOnly?: boolean
  isNewCustomer?: boolean
  updatedBy?: number
  updatedDate?: string //Date - ISO
  createdBy?: number
  createDate?: string //Date - ISO
  notify?: boolean
  setUpBy?: string
  setUpByEmail?: string
  supplyNotes?: string
  notes?: string
  useIRForTotalBaseNumber?: number //Only 0 or 1.
  doNotPerformIMGTest?: boolean
  expressDistribution?: boolean
  latitude?: number
  longitude?: number
  shippingContact: PlantContactPostDTO
  plantContact: PlantContactPostDTO
}

export type PostPlantsQuery = {
  plants: PostPlantsDTO[]
}

export type PlantContactPostDTO = {
  lastName: string
  firstName: string
  title?: string
  phone?: string
  email?: string
  report?: boolean
  summaryReport?: boolean
  datafileReport?: boolean
  address?: TContactAddress
}

export type PostPlantsDTO = {
  name: string
  locationID: number // 1 or 2.
  customerID: number
  number?: number
  plantShippingContactID?: number
  primaryPlantContactID?: number
  plantCustomerContactID?: number
  industryCode?: string
  distributeAbnormalReportsOnly?: boolean
  isNewCustomer?: boolean
  updatedBy?: number
  updatedDate?: string //Date - ISO
  createdBy?: number
  createDate?: string //Date - ISO
  notify?: boolean
  setUpBy?: string
  setUpByEmail?: string
  supplyNotes?: string
  notes?: string
  useIRForTotalBaseNumber?: number //Only 0 or 1.
  doNotPerformIMGTest?: boolean
  expressDistribution?: boolean
  latitude?: number
  longitude?: number
  shippingContact: PlantContactPostDTO
  plantContact: PlantContactPostDTO
}

/**Returned when posting plants via PostPlantsQuery. */
export type PostPlantsResponse = PostPlantsResponseDTO[]

export type PostPlantsResponseDTO = {
  linkID?: number
  financialID?: string
  plantID: number
  customerID: number
  plantNumber: number
  plantName: string
  paymentType?: string
  autoInvoice?: string
  minimumInvoice?: string
  invoicePeriod?: number
  linkLevel?: LinkLevel
  linkStatus: LinkStatus
}

export type GetBillingPlantsResponse = GetBillingPlantsResponseDTO[]

export type GetBillingPlantsResponseDTO = {
  linkID?: number
  financialID?: string
  plantID: number
  customerID: number
  plantNumber: number
  plantName: string
  paymentType?: string
  autoInvoice?: string
  minimumInvoice?: string
  invoicePeriod?: number
  linkLevel?: LinkLevel
  linkStatus: LinkStatus
  latitude?: number
  longitude?: number
}

export type GetPlantsResponse = GetPlantsResponseDTO[]

export type GetPlantsResponseDTO = {
  plantID: number
  customerID: number
  plantNumber: number
  plantName: string
  paymentType?: string
  autoInvoice?: string
  minimumInvoice?: string
  invoicePeriod?: number
  latitude?: number
  longitude?: number
}
// !SECTION Plant

// SECTION Machine
/**Post machines under the specified plant. Authorization is required. */
export type PostMachinesQuery = {
  machines: PostMachinesDTO[]
}

export type PatchMachinesQuery = {
  machines: PatchMachinesDTO[]
}

export type PostEncryptedMachinesQuery = PostMachinesQuery & TEncrypted

export type PatchEncryptedMachinesQuery = PatchMachinesQuery & TEncrypted

export type PatchMachinesDTO = {
  pointID: number
} & PostMachinesDTO

export type PostMachinesDTO = {
  plantID: number
  name: string
  machineType: string
  portTypeID?: number
  customerEquipmentID: string
  lubricantID: number
  number?: number
  manufacturer: string
  modelName: string
  dutyCycle?: number
  customerEquipmentType?: string
  builder?: string
  identifier: string
  notes: string
  routeID?: number
  latitude?: number
  longitude?: number
  criticalityRating?: number
  sampleSourceRating?: number
  testSchedules?: PostMachineTestScheduleDTO[]
  lubricantManufacturer?: string
  samplingInstructions?: string
}

export interface PostMachineDTO {
  plantID: number
  name: string
  machineType: string
  portType: string
  customerEquipmentID: string
  lubricantID: number
  number: number | null
  manufacturer: string
  modelName: string
  dutyCycle: number | null
  customerEquipmentType: string
  builder: string
  identifier: string
  notes: string
  routeID: number | null
  latitude: number | null
  longitude: number | null
  criticalityRating: number | null
  sampleSourceRating: number | null
}

export type PostMachineTestScheduleDTO = {
  testGroupID: number //int32
  pointID: number //int32
  startMonth: number //byte
  frequency: number //byte
}

export type GetMachinesQuery = {
  customerIDs?: number[]
  plantIDs?: number[]
  pointIDs?: number[]
  labIDs?: number[]
}

export type GetMachinesWithEncryptedBottleIdentifierQuery = {
  encryptedBottleIdentifier: string
  plantIDs?: number[]
  pointIDs?: number[]
  labIDs?: number[]
}

export type GetMachinesResponse = MachinesResponseDTO[]

export type MachinesResponseDTO = {
  customerID: number
  plantID: number
  pointID: number
  machineName: string
  customerEquipmentID: string
  machineType?: string
}

/**Returned when posting machines via PostMachinesQuery. */
export type PostMachinesResponse = PostMachinesResponseDTO[]

export type PostMachinesResponseDTO = {
  customerID: number
  plantID: number
  pointID: number
  machineName: string
  customerEquipmentID: string
  machineType?: string
}

export type Route = {
  number: number
  id: number
  name: string
}

export type GetRoutes = { customerID: number }

export type GetMachineDetail = { pointID: number }

export type GetMachineDetailEncrypted = { pointID: number } & TEncrypted

export type GetRoutesEncrypted = {} & TEncrypted

export type GetAllMachineManufacturersEncrypted = {} & TEncrypted

export type GetAllMachineTypesEncrypted = {} & TEncrypted

export type GetAllMachinePortTypesEncrypted = {} & TEncrypted

export type GetAllFluidTypesEncrypted = {} & TEncrypted

export type GetAllFluidManufacturersEncrypted = {} & TEncrypted

export type GetAllCustomerPricingEncrypted = {} & TEncrypted

export type GetFluidDetailsEncrypted = {
  id: number
} & TEncrypted

export type GetFluidDetails = {
  id: number
}

export type TEncrypted = {
  encryptedIdentifier: string
}

export type TListType = {
  name: string
  code: number
  shortCode: string
}

export type TIdentityType = {
  name: string
  id: number
}

export type FluidDetails = {
  manufacturerShortCode: string
  viscosity40: number | null
  viscosity100: number | null
  type: string | null
  grade: string | null
  brand: string | null
  hasDocumentation: boolean
} & TIdentityType

export type FluidType = {
  manufacturerShortCode: string
} & TIdentityType

// !SECTION Machine

//SECTION Warhammer Samples
export type GetWarhammerSamples = {
  bottleIdentifiers: string[]
}

export type GetEncryptedWarhammerSamples = {
  encryptedBottleIdentifiers: string[]
}

export type PutWarhammerSamples = {
  userID?: number
  samples: WarhammerSamplePutDTO[]
}

export type WarhammerSamplePutDTO = {
  bottleIdentifier: string
  pointID: number
  sampledAt?: string //ISO-Date
  comments?: string
  machineHours?: number
  lubeHours?: humber
  oilChangedAt?: string
  fluidID?: number
}

export type PutEncryptedWarhammerSamples = Omit<
  PutWarhammerSamples,
  'samples'
> & {
  samples: EncryptedWarhammerSamplePutDTO[]
}

export type EncryptedWarhammerSamplePutDTO = Omit<
  WarhammerSamplePutDTO,
  'bottleIdentifier'
> & {
  encryptedBottleIdentifier: string
}

export type WarhammerSampleResponse = WarhammerSample[]
export type WarhammerSample = {
  customerName?: string
  plantName?: string
  machineName?: string
  plantNumber?: number
  bottleIdentifier: string
  customerID: number
  plantID: number
  pointID: number
  sampledAt: string //Date
  comments: string
  machineHours?: number
  lubeHours?: number
  userID: number
  oilChangedAt?: string //Date
  fluidID: number
  labID?: number
}

export type EncryptedWarhammerSampleResponse = EncryptedWarhammerSample[]
export type EncryptedWarhammerSample = Omit<
  WarhammerSample,
  'bottleIdentifier'
> & {
  encryptedBottleIdentifier: string
}

//!SECTION

//SECTION SampleStatus

export type GetSamples = {
  customerIDs?: number[]
  plantsIDs?: number[]
  pointIDs?: number[]
  labIDs?: number[]
  poNumbers?: string[]
  ReceivedDateStart?: string
  ReceivedDateEnd?: string
  ReportDateStart?: string
  ReportDateEnd?: string
  SampledDateStart?: string
  SampledDateEnd?: string
}

export type Sample = {
  labID: number
  pointID: number
  sampleDate: string
  sampleStatus: string
  lubricantTypeID: number
  lubricantName: string
  machineCondition?: Condition
  lubeCondition?: Condition
  sequenceNumber?: number
  receivedDate?: string //date
  reportDate?: string //date
  testGroups: TestGroup[]
  recommendations?: string
  problems?: string
  reportNotes?: string
  observationNotes?: string
  cGRecommendations?: string
}

export type TestGroup = {
  testGroupID: number
  testGroup: string
  waterScreen: boolean
  reportType: number
  active: number
  updatedDate: string
  updatedBy?: number
  tests: TestCode[]
}

export type TestCode = {
  testID: number
  testCode: string
  testDesc: string
  units: string
  outside: boolean
  sampleAmount: string
  detailedDesc: string
}

export type ChangeUserPassword = {
  username: string
  currentPassword: string
  newPassword
}

//!SECTION SampleStatus

/**
 * USER PROFILE QUERY

user {
  firstName,
  lastName,
  loginID,
  readOnly
},
divisions {
  iD,
  name,
  customers {
      custID
  }
},
customers {
  custID,
  custName,
  routes {
      routeID,
      name
  },
  plants {
      plantID,
      plantName,
      plantNo,
      machines {
          pointID,
          machineName,
          machType,
          machMFG,
          modelName,
          machNo,
          lubricant {
              lubeTypeID,
              lubricantName
          }
      }
  }
},
correctiveActions: listItems(listType: 73, locationID: 1) {
  listValue,
  listDesc
},
machineTypes: listItems(listType: 28, locationID: 1) {
  listValue,
  listDesc
},
machineMfgs: listItems(listType: 27, locationID: 1) {
  listValue,
  listDesc
},
lubeMfrs {
  pickListValue,
  lubemfr
},
testGroups {
  testGroupID,
  testGroupName
}
*/
// // // // // //
