import React from 'react'
import IssueBanner from '../IssueBanners/IssueBanner'

interface IProps {
    showBanner: boolean;
}

const FinancialHoldBanner: React.FC<IProps> = (props: IProps) => {
    return (
        <IssueBanner
            onBannerClick={() => null}
            severity={'error'}
            title="Financial Alerts"
            showBanner={props.showBanner}
        >
            <>
                <span style={{ display: 'block', marginBottom: '5px' }}>
                    You have samples that need ATTENTION. Contact Customer Service Team at (216) 251-2510.
                </span>
            </>
        </IssueBanner>
    )
}

export default FinancialHoldBanner