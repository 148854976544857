/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ViewYourReportsBox } from 'components/Boxes/ViewYourReportsBox'
import { AccordionActions, Box, Grid, Typography } from '@material-ui/core'
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from '@material-ui/core/styles'
import Exceptions90DaysIcon from '@material-ui/icons/AvTimer'
import ExceptionsIcon from '@material-ui/icons/ErrorOutline'
import SampleCountIcon from '@material-ui/icons/ShowChart'
import * as Moment from 'moment'
import { extendMoment } from 'moment-range'

import {
  TAPICustomer,
  TAPIPlant,
  TAPIProfileDivision,
  TAPISample,
  TNoSalesOrderQuery,
  TNoSalesOrderSample,
} from 'types/api'
import AppLayout from 'components/AppLayout'
import SampleIssueBanner from 'components/Banners/IssueBanners/SampleIssueBanner'
import { ResetButton, SearchButton } from 'components/buttons'
import { DashboardConditionDateFilter } from 'components/filters'
import WithDisableFiltersBox from 'components/filters/SmartFilter/DisableFiltersBox/WithDisableFiltersBox'
import {
  Filters,
  TFilterState,
  TSmartFilter,
} from 'components/filters/SmartFilter/SmartFilterTypes'
import WithSmartFilters from 'components/filters/SmartFilter/WithSmartFilters'
import Loader from 'components/Loader'
import { TSampleSnapshot } from 'components/MachineCard'
import MachineCardSlider from 'components/MachineCardSlider'
import OfflineBanner from 'components/offline-banner'
import SEO from 'components/SEO'
import LastHundredSamplesTable from 'components/tables/LastHundredSamples'
import { RecurringExceptionsTable } from 'components/tables/RecurringExceptions'
import TableWrapper from 'components/TableWrapper'
import { useNonInitialEffect } from 'hooks/useNonInitialEffect'
import APIService from 'services/api-service'
import LoginService from 'services/login-service'

import coreTheme from '../components/theme'
import { useProfile } from 'Contexts/ProfileContext'
import ComponentMask from 'components/ComponentMask'
import { WrappedResponsiveLineChart } from 'components/Charts/WrappedCharts/WrappedResponsiveLineChart'
import { WrappedResponsiveBarChart } from 'components/Charts/WrappedCharts/WrappedResponsiveBarChart'
import { Link } from 'react-router-dom'
import FinancialAlertBanner from 'components/Banners/IssueBanners/FinancialAlertBanner'
import { CancellablePromise } from 'utils/CancellablePromise'
import FinancialHoldModal from 'components/Banners/FinancialHold/FinancialHoldModal'
import FinancialHoldBanner from 'components/Banners/FinancialHold/FinancialHoldBanner'

const moment = extendMoment(Moment)
let defaults = {
  selectedCustomers: [] as TAPICustomer[],
  selectedDivisions: [] as TAPIProfileDivision[],
  selectedPlants: [] as TAPIPlant[],
}
const styles = theme => ({
  card: {
    padding: '0px',
  },
  cardHeader: {
    backgroundColor: coreTheme.palette.primary.main,
    color: coreTheme.palette.primary.contrastText,
    padding: '10px',
  },
  cardHeaderLink: {
    color: coreTheme.palette.primary.contrastText,
    '&:hover': {
      color: coreTheme.palette.secondary.contrastText,
    },
  },
  cardContent: {
    padding: '0px',
  },
  orgFilter: {
    width: '300px',
    display: 'inline-block',
    marginLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
      marginLeft: '0px',
    },
  },
  DashboardConditionDate: {
    width: '300px',
  },
})

let ongoingCancellablePromises = [] as CancellablePromise<unknown>[]

const Dashboard = ({ classes, offline, onError }) => {
  const [state, setState] = useState({
    loaded: false,
    customers: [] as TAPICustomer[],
    divisions: [] as TAPIProfileDivision[],
    plants: [] as TAPIPlant[],
    samplesSnapshotLoaded: false,
    samplesSnapshotDatesLoaded: false,
    samplesSnapshot: null as TSampleSnapshot[],
    samplesSnapshotDates: null,
    samplesComplianceLoaded: false,
    samplesCompliance: null,
    exceptionsLoaded: false,
    exceptions: null,
    exceptionsSummaryLoaded: false,
    exceptionsSummary: null,
    recurringExceptionsLoaded: false,
    recurringExceptions: null,
    lastSamplesLoaded: false,
    lastSamples: null as TAPISample[],
    selectedCustomers: [] as TAPICustomer[],
    selectedDivisions: [] as TAPIProfileDivision[],
    selectedPlants: [] as TAPIPlant[],
    selectedDivisionIDs: [] as number[],
    selectedCustomerIDs: [] as number[],
    selectedPlantIDs: [] as number[],
    selectedSamplesSnapshotDate: null,
    isBusy: true,
    samplesSnapshotLoading: false,
    initialFilterStateLoaded: false,
  })

  const [financialHoldSamples, setFinancialHoldSamples] = useState<TNoSalesOrderSample[]>([]);
  const [openFinancialHoldModal, setOpenFinancialHoldModal] = useState<boolean>(false);

  const profileContext = useProfile()

  useEffect(() => {
    ongoingCancellablePromises = []
    return () => {
      while (ongoingCancellablePromises.length > 0) {
        const promise = ongoingCancellablePromises.pop()
        promise.abortController?.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (
      profileContext.minimumProfileLoaded &&
      state.loaded &&
      !state.initialFilterStateLoaded
    ) {
      setState(prev => ({
        ...prev,
        ...profileContext.profile,
        selectedCustomers:
          profileContext.dependentData.filters.initialFilterState?.customers ??
          [],
        selectedDivisions:
          profileContext.dependentData.filters.initialFilterState?.divisions ??
          [],
        initialFilterStateLoaded: true,
      }))
    } else {
      setState(prev => ({ ...prev, ...profileContext.profile }))
    }
  }, [
    profileContext,
    profileContext.dependentData.filters.defaultValues,
    state.loaded,
  ])

  const checkLoadError = useCallback(
    data => {
      if (typeof data.errors !== 'undefined') {
        onError(data)
        return true
      }

      return false
    },
    [onError]
  )

  async function loadFinancialHoldSamples(customerIDs: number[]) {
    if (customerIDs.length < 1) return;

    let promise

    try {
      promise = APIService.getNoSalesOrderSamples({
        customerIDs: customerIDs
      } as TNoSalesOrderQuery)

      ongoingCancellablePromises.push(promise)

      const response = await promise as TNoSalesOrderSample[]

      setFinancialHoldSamples(response)
    } catch (error) {
      console.error()
    } finally {
      ongoingCancellablePromises = ongoingCancellablePromises.filter(
        p => p !== promise
      )
    }
  }

  const loadSamplesSnapshotDates = async variables => {
    let samplesSnapshotDatesArray = []

    const promise = APIService.getDashboardSamplesSnapshotDates(variables)
    ongoingCancellablePromises.push(promise)
    const data = await promise
    ongoingCancellablePromises.filter(p => p != promise)

    if (checkLoadError(data)) return

    if (
      typeof data.analytics.samplesSnapshotDates !== 'undefined' &&
      data.analytics.samplesSnapshotDates !== null
    ) {
      samplesSnapshotDatesArray = data.analytics.samplesSnapshotDates.map(x => {
        return {
          month: x.month,
          year: x.year,
          id: `${x.year}-${x.month}`,
          text: moment(new Date(x.year, x.month - 1, 1)).format('MMMM YYYY'),
        }
      })
    }

    setState(prev => ({
      ...prev,
      samplesSnapshotDatesLoaded: true,
      samplesSnapshotDates: samplesSnapshotDatesArray,
      selectedSamplesSnapshotDate:
        !selectedSamplesSnapshotDate && samplesSnapshotDatesArray.length > 0
          ? samplesSnapshotDatesArray[0]
          : selectedSamplesSnapshotDate,
    }))
  }

  const loadSamplesCompliance = useCallback(
    async (defaultRangeData, variables) => {
      const samplesComplianceModel = [
        {
          id: 'Samples Due',
          data: defaultRangeData.slice(),
        },
        {
          id: 'Samples Received',
          data: defaultRangeData.slice(),
        },
      ]

      const promise = APIService.getDashboardSamplesCompliance(variables)
      ongoingCancellablePromises.push(promise)
      const data = await promise

      ongoingCancellablePromises.filter(p => p != promise)
      if (checkLoadError(data)) return

      if (
        typeof data.analytics.samplesDueByMonth !== 'undefined' &&
        data.analytics.samplesDueByMonth !== null
      ) {
        samplesComplianceModel[0].data = samplesComplianceModel[0].data.map(
          datum => {
            const found = data.analytics.samplesDueByMonth.find(
              sample => sample.month === datum.month
            )

            return {
              x: datum.x,
              y: typeof found === 'undefined' ? 0 : found.count,
            }
          }
        )
      }

      if (
        typeof data.analytics.samplesReceivedByMonth !== 'undefined' &&
        data.analytics.samplesReceivedByMonth !== null
      ) {
        samplesComplianceModel[1].data = samplesComplianceModel[1].data.map(
          datum => {
            const found = data.analytics.samplesReceivedByMonth.find(
              sample =>
                sample.year === datum.year && sample.month === datum.month
            )

            return {
              x: datum.x,
              y: typeof found === 'undefined' ? 0 : found.count,
            }
          }
        )
      }

      setState(prev => ({
        ...prev,
        samplesComplianceLoaded: true,
        samplesCompliance: samplesComplianceModel,
      }))
    },
    [checkLoadError]
  )

  const loadExceptions = useCallback(
    async (defaultRangeData, variables) => {
      const exceptionsModel = [
        {
          id: 'Machine Exceptions',
          data: defaultRangeData.slice(),
        },
        {
          id: 'Lube Exceptions',
          data: defaultRangeData.slice(),
        },
      ]
      const promise = APIService.getDashboardExceptions(variables)
      ongoingCancellablePromises.push(promise)
      const data = await promise

      ongoingCancellablePromises.filter(p => p != promise)
      if (checkLoadError(data)) return

      if (
        typeof data.analytics.exceptionsByMonth !== 'undefined' &&
        data.analytics.exceptionsByMonth !== null
      ) {
        exceptionsModel[0].data = exceptionsModel[0].data.map(datum => {
          const found = data.analytics.exceptionsByMonth.find(
            sample => sample.year === datum.year && sample.month === datum.month
          )

          return {
            x: datum.x,
            y: typeof found === 'undefined' ? 0 : found.machineCount,
          }
        })

        exceptionsModel[1].data = exceptionsModel[1].data.map(datum => {
          const found = data.analytics.exceptionsByMonth.find(
            sample => sample.year === datum.year && sample.month === datum.month
          )

          return {
            x: datum.x,
            y: typeof found === 'undefined' ? 0 : found.lubeCount,
          }
        })
      }

      setState(prev => ({
        ...prev,
        exceptionsLoaded: true,
        exceptions: exceptionsModel,
      }))
    },
    [checkLoadError]
  )

  const loadExceptionsSummary = useCallback(
    async variables => {
      const exceptionsSummaryModel = [
        {
          id: 'Wear Metals',
          value: 0,
        },
        {
          id: 'Ferrous Wear',
          value: 0,
        },
        {
          id: 'Particle Count',
          value: 0,
        },
        {
          id: 'Water',
          value: 0,
        },
        {
          id: 'Viscosity',
          value: 0,
        },
        {
          id: 'Degradation',
          value: 0,
        },
        {
          id: 'Other',
          value: 0,
        },
      ]
      const promise = APIService.getDashboardExceptionsSummary(variables)
      ongoingCancellablePromises.push(promise)
      const data = await promise
      ongoingCancellablePromises.filter(p => p != promise)

      if (checkLoadError(data)) return

      if (
        typeof data.analytics.exceptionsSummary !== 'undefined' &&
        data.analytics.exceptionsSummary !== null
      ) {
        const summary = data.analytics.exceptionsSummary

        const findAndSet = (id, value) => {
          const item = exceptionsSummaryModel.find(x => x.id === id)
          item.value = value
          item[id] = value
        }

        findAndSet('Wear Metals', summary.wearMetals)
        findAndSet('Ferrous Wear', summary.ferrousWear)
        findAndSet('Particle Count', summary.particleCount)
        findAndSet('Water', summary.water)
        findAndSet('Viscosity', summary.viscosity)
        findAndSet('Degradation', summary.degradation)
        findAndSet('Other', summary.other)
        exceptionsSummaryModel.sort((a, b) => b.value - a.value)
      }

      setState(prev => ({
        ...prev,
        exceptionsSummaryLoaded: true,
        exceptionsSummary: exceptionsSummaryModel,
      }))
    },
    [checkLoadError]
  )

  const loadRecurringExceptions = useCallback(
    async variables => {
      let recurringExceptionsModel = []

      const promise = APIService.getDashboardRecurringExceptions(variables)

      ongoingCancellablePromises.push(promise)
      const data = await promise

      ongoingCancellablePromises.filter(p => p != promise)

      if (checkLoadError(data)) return

      if (
        typeof data.analytics.recurringExceptions !== 'undefined' &&
        data.analytics.recurringExceptions !== null
      ) {
        recurringExceptionsModel = data.analytics.recurringExceptions
      }

      setState(prev => ({
        ...prev,
        recurringExceptionsLoaded: true,
        recurringExceptions: recurringExceptionsModel,
      }))
    },
    [checkLoadError]
  )

  const loadLastSamples = useCallback(
    async variables => {
      let lastSamplesModel = []

      //Get cancellable promise
      const promise = APIService.getDashboardLastSamples(variables)

      ongoingCancellablePromises.push(promise)
      const data = await promise
      ongoingCancellablePromises.filter(p => p != promise)

      if (checkLoadError(data)) return

      const pagedSamples = data.pagedSamples

      if (typeof pagedSamples !== 'undefined' && pagedSamples !== null) {
        lastSamplesModel = pagedSamples.items
      }

      setState(prev => ({
        ...prev,
        lastSamplesLoaded: true,
        lastSamples: lastSamplesModel,
      }))
    },
    [checkLoadError]
  )

  const loadDashboardData = useCallback(() => {
    const periodMonths = 18
    const lineRange = moment
      .rangeFromInterval('month', -periodMonths, moment())
      .by('month')

    const defaultRangeData = []
    for (const month of lineRange) {
      defaultRangeData.push({
        x: month.toDate(),
        y: 0,
        year: month.year(),
        month: month.month() + 1,
      })
    }
    setState(prev => ({
      ...prev,
      exceptionsLoaded: false,
      exceptionsSummaryLoaded: false,
      lastSamplesLoaded: false,
      recurringExceptionsLoaded: false,
      samplesComplianceLoaded: false,
      samplesSnapshotLoaded: false,
    }))
    const plantIDs = state.selectedPlants.map(x => x.plantID)

    let customerIDs =
      plantIDs.length > 0
        ? [
          ...state.customers
            .filter(cust =>
              cust.plants
                .map(plant => plant.plantID)
                .some(custPlantID => plantIDs.includes(custPlantID))
            )
            .map(cust => cust.custID),
        ]
        : state.selectedCustomers.map(x => x.custID)

    customerIDs =
      plantIDs.length > 0 || customerIDs.length > 0
        ? customerIDs
        : state.selectedDivisions === undefined
          ? []
          : state.selectedDivisions
            .map(x => x.customers.map(y => y.custID))
            .flat()
    
    loadFinancialHoldSamples(customerIDs)

    loadSamplesSnapshotDates({
      customerIDs: customerIDs,
      plantIDs: plantIDs,
    })

    loadSamplesCompliance(defaultRangeData, {
      periodMonths: periodMonths,
      customerIDs: customerIDs,
      plantIDs: plantIDs,
    })

    loadExceptions(defaultRangeData, {
      periodMonths: periodMonths,
      customerIDs: customerIDs,
      plantIDs: plantIDs,
    })

    loadExceptionsSummary({
      customerIDs: customerIDs,
      plantIDs: plantIDs,
    })

    loadRecurringExceptions({
      periodMonths: periodMonths,
      customerIDs: customerIDs,
      plantIDs: plantIDs,
    })

    loadLastSamples({
      customerIDs: customerIDs,
      plantIDs: plantIDs,
    })
  }, [
    loadExceptions,
    loadExceptionsSummary,
    loadLastSamples,
    loadRecurringExceptions,
    loadSamplesCompliance,
    loadSamplesSnapshotDates,
    loadFinancialHoldSamples,
    state,
  ])

  const setInitialState = useCallback((): Partial<TFilterState> => {
    const { divisions, customers } = profileContext.profile
    const selectedDivisions =
      divisions?.length > 0 && customers?.length > 0
        ? [
          divisions.find(x =>
            x.customers.some(y => y.custID === customers[0].custID)
          ),
        ]
        : []
    const selectedCustomers = customers?.length > 0 ? [customers[0]] : []

    if (selectedDivisions[0] !== undefined) {
      return {
        divisions: selectedDivisions,
        customers: selectedCustomers,
      } as Partial<TFilterState>
    } else {
      return {
        customers: selectedCustomers,
      } as Partial<TFilterState>
    }
  }, [profileContext.profile, profileContext.minimumProfileLoaded])

  useEffect(() => {
    const initialState = setInitialState()
    defaults = {
      ...defaults,
      selectedDivisions: initialState.divisions ?? [],
      selectedCustomers: initialState.customers,
    }
  }, [])

  const loadDashboardPage = useCallback(async () => {
    const loggedIn = await LoginService.isLoggedIn()
    if (!loggedIn) return
    if (!state.loaded) {
      const initialState = setInitialState()
      setState(prev => ({
        ...prev,
        ...profileContext.profile,
        selectedDivisions: initialState.divisions ?? [],
        selectedCustomers: initialState.customers,
        selectedPlants: [],
        loaded: true,
      }))
    }
  }, [profileContext.profile, state.loaded])

  const loadSamplesSnapshot = useCallback(async () => {
    let samplesSnapshotModel = []
    setState(prev => ({
      ...prev,
      samplesSnapshotLoading: true,
    }))

    if (state.selectedSamplesSnapshotDate === null) {
      setState(prev => ({
        ...prev,
        samplesSnapshotLoaded: true,
        samplesSnapshotLoading: false,
        samplesSnapshot: [],
      }))
    } else {
      const plantIDs = state.selectedPlants.map(x => x.plantID)

      let customerIDs =
        plantIDs.length > 0
          ? [
            ...state.customers
              .filter(cust =>
                cust.plants
                  .map(plant => plant.plantID)
                  .some(custPlantID => plantIDs.includes(custPlantID))
              )
              .map(cust => cust.custID),
          ]
          : state.selectedCustomers.map(x => x.custID)

      customerIDs =
        plantIDs.length > 0 || customerIDs.length > 0
          ? customerIDs
          : state.selectedDivisions === undefined
            ? []
            : state.selectedDivisions
              .map(x => x.customers.map(y => y.custID))
              .flat()

      try {
        // the information being sent HERE is outdated. The dates are incorrect.
        const promise = APIService.getDashboardSamplesSnapshot({
          year: state.selectedSamplesSnapshotDate.year,
          month: state.selectedSamplesSnapshotDate.month,
          customerIDs: customerIDs,
          plantIDs: plantIDs,
        })

        ongoingCancellablePromises.push(promise)
        const data = await promise

        ongoingCancellablePromises.filter(p => p != promise)

        if (!checkLoadError(data)) {
          if (
            typeof data.analytics.samplesSnapshot !== 'undefined' &&
            data.analytics.samplesSnapshot !== null
          ) {
            samplesSnapshotModel = data.analytics.samplesSnapshot
          }
        }
      } catch (error) {
        onError(error)
      } finally {
        setState(prev => ({
          ...prev,
          samplesSnapshotLoaded: true,
          samplesSnapshotLoading: false,
          samplesSnapshot: samplesSnapshotModel,
        }))
      }
    }
  }, [
    checkLoadError,
    state.selectedCustomers,
    state.selectedDivisions,
    state.selectedPlants,
    state.selectedSamplesSnapshotDate,
  ])

  useEffect(() => {
    loadDashboardPage()
  }, [])

  useNonInitialEffect(() => {
    if (
      profileContext.minimumProfileLoaded &&
      profileContext.dependentData.filters.minimumFilterOptionsLoaded &&
      state.initialFilterStateLoaded
    )
      loadDashboardData()
  }, [
    state.selectedDivisions,
    state.selectedCustomers,
    state.selectedPlants,
    profileContext.minimumProfileLoaded,
    profileContext.dependentData.filters.minimumFilterOptionsLoaded,
  ])

  useEffect(() => {
    if (
      state.samplesSnapshotDatesLoaded &&
      !state.samplesSnapshotLoaded &&
      !state.samplesSnapshotLoading
    ) {
      loadSamplesSnapshot()
    }
  }, [
    state.samplesSnapshotDatesLoaded,
    state.samplesSnapshotDates,
    state.samplesSnapshotLoaded,
    state.samplesSnapshotLoading,
  ])

  const handleSamplesSnapshotDateChanged = event => {
    setState(prev => ({
      ...prev,
      selectedSamplesSnapshotDate: state.samplesSnapshotDates.find(
        x => x.id === event.target.value
      ),
    }))
  }

  useEffect(() => {
    setState(prev => ({
      ...prev,
      isBusy:
        !state.exceptionsLoaded ||
        !state.lastSamplesLoaded ||
        !state.samplesSnapshotLoaded ||
        !state.exceptionsSummaryLoaded ||
        !state.samplesComplianceLoaded ||
        !state.recurringExceptionsLoaded ||
        !state.samplesSnapshotDatesLoaded,
    }))
  }, [
    state.loaded,
    state.exceptionsLoaded,
    state.lastSamplesLoaded,
    state.samplesSnapshotLoaded,
    state.exceptionsSummaryLoaded,
    state.samplesComplianceLoaded,
    state.recurringExceptionsLoaded,
    state.samplesSnapshotDatesLoaded,
  ])

  const {
    customers,
    samplesSnapshotLoaded,
    samplesSnapshot,
    samplesSnapshotDates,
    samplesComplianceLoaded,
    samplesCompliance,
    exceptionsLoaded,
    exceptions,
    exceptionsSummaryLoaded,
    exceptionsSummary,
    recurringExceptionsLoaded,
    recurringExceptions,
    lastSamplesLoaded,
    lastSamples,
    selectedSamplesSnapshotDate,
  } = state

  const startDate =
    state.selectedSamplesSnapshotDate !== null
      ? moment(
        new Date(
          state.selectedSamplesSnapshotDate.year,
          state.selectedSamplesSnapshotDate.month - 1,
          1
        )
      )
      : moment()
  const endDate =
    state.selectedSamplesSnapshotDate !== null
      ? moment(startDate.toDate()).add(1, 'month').subtract(1, 'day')
      : moment()

  const startDateParam = startDate.format()
  const endDateParam = endDate.format()

  const injectSmartFilterState = async (smartState: TFilterState) => {
    setState(prev => ({
      ...prev,
      selectedCustomers: smartState.customers,
      selectedDivisions: smartState.divisions,
      selectedPlants: smartState.plants,
    }))
  }

  const handleResetClick = (reset: any) => {
    reset()
    setState(prev => ({
      ...prev,
      ...defaults,
      count: 0,
      selectedPlants: [],
      selectedSamplesSnapshotDate: null,
      samplesSnapshotDatesLoaded: false,
    }))
  }
  const handleSearchClicked = async (
    filterState: TFilterState,
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    injectSmartFilterState(filterState)
    setState(prev => ({ ...prev, samplesSnapshotDatesLoaded: false }))
  }

  const theme = createTheme(
    {
      overrides: {
        MuiGrid: {
          root: {
            justifyContent: 'flex-end',
            [coreTheme.breakpoints.down('sm')]: {
              justifyContent: 'space-between',
            },
          },
        },
      },
    },
    coreTheme
  )

  const [layoutRendered, setLayoutRendered] = useState(false)
  useLayoutEffect(() => {
    setLayoutRendered(true)
  }, [])
  const findyourSamplesCardRef = useRef<HTMLDivElement>()

  const filterOptionsForLastHundredSamples: TFilterState = {
    divisions: state.selectedDivisions,
    customers: state.selectedCustomers,
    plants: state.selectedPlants,
    reportTypes: [],
    customerEquipmentIDs: [],
    lubricantConditions: [],
    lubricantManufacturers: [],
    lubricantTypes: [],
    machineConditions: [],
    machineManufacturers: [],
    machineModels: [],
    machineNames: [],
    machineNumbers: [],
    machinePicklistTypes: [],
    machines: [],
    routes: [],
    testPackages: [],
    samples: [],
  }

  const filterComponent = useMemo(() => {
    if (profileContext.minimumProfileLoaded) {
      return (
        <WithDisableFiltersBox>
          {useFilters => (
            <WithSmartFilters
              initialState={setInitialState()}
              filterOptions={profileContext.dependentData.filters.filterOptions}
              disableOptionFiltering={!useFilters}
              filters={[Filters.Plant, Filters.Customers, Filters.Divisions]}
            >
              {({
                filterState,
                filterOptions,
                reset,
                filters,
              }: TSmartFilter) => {
                return (
                  <>
                    {
                      <p
                        style={{
                          padding: '2rem',
                          fontSize: '1.7rem',
                          margin: '0',
                        }}
                      >
                        Find Your Samples
                      </p>
                    }
                    <form
                      onSubmit={e => handleSearchClicked(filterState, e)}
                      id="find_your_samples-form"
                    >
                      <Grid
                        container
                        direction="row"
                        alignItems="flex-end"
                        spacing={2}
                        justify="center"
                      >
                        {profileContext.profile.divisions?.length > 0 ? (
                          <Grid item>
                            <ComponentMask
                              loaded={profileContext.minimumProfileLoaded}
                              childRenderHeight={'50px'}
                              childRenderWidth="300px"
                              loaderType="line"
                            >
                              <filters.SmartDivisionFilter
                                disabled={state.divisions?.length === 0}
                                style={{
                                  width: 300,
                                  display: 'inline-block',
                                }}
                              />
                            </ComponentMask>
                          </Grid>
                        ) : (
                          ''
                        )}
                        {profileContext.profile.customers?.length > 1 ? (
                          <Grid item>
                            <ComponentMask
                              loaded={profileContext.minimumProfileLoaded}
                              childRenderHeight={'50px'}
                              childRenderWidth="300px"
                              loaderType="line"
                            >
                              <filters.SmartCustomerFilter
                                disabled={state.customers?.length === 0}
                                style={{
                                  width: 300,
                                  display: 'inline-block',
                                }}
                              />
                            </ComponentMask>
                          </Grid>
                        ) : (
                          ''
                        )}
                        <Grid item spacing={0}>
                          <ComponentMask
                            loaded={profileContext.minimumProfileLoaded}
                            childRenderHeight={'50px'}
                            childRenderWidth="300px"
                            loaderType="line"
                          >
                            <filters.SmartPlantFilter
                              disabled={
                                !profileContext.minimumProfileLoaded ||
                                profileContext.dependentData.filters
                                  .filterOptions?.plants?.length === 0
                              }
                              style={{
                                width: 300,
                                display: 'inline-block',
                              }}
                            />
                          </ComponentMask>
                        </Grid>

                        <Grid item>
                          <ComponentMask
                            loaded={profileContext.minimumProfileLoaded}
                            childRenderHeight={'50px'}
                            childRenderWidth="300px"
                            loaderType="line"
                          >
                            <DashboardConditionDateFilter
                              options={samplesSnapshotDates || []}
                              value={selectedSamplesSnapshotDate}
                              onChange={handleSamplesSnapshotDateChanged}
                              disabled={
                                !state.samplesSnapshotDates ||
                                !state.samplesSnapshotDatesLoaded ||
                                state.samplesSnapshotDates.length === 0
                              }
                              className={classes.DashboardConditionDate}
                            />
                          </ComponentMask>
                        </Grid>
                      </Grid>
                      <ThemeProvider theme={theme}>
                        <div style={{ marginTop: '2rem' }}>
                          <AccordionActions
                            style={{ justifyContent: 'center' }}
                          >
                            <Grid
                              container
                              item
                              direction="row"
                              spacing={3}
                              xs={12}
                              justify={'center'}
                            >
                              <Grid item>
                                <SearchButton id="find_your_samples-search_button" />
                              </Grid>
                              <Grid item>
                                <ResetButton
                                  id="find_your_samples-reset_buton"
                                  onResetClick={() => handleResetClick(reset)}
                                />
                              </Grid>
                            </Grid>
                          </AccordionActions>
                        </div>
                      </ThemeProvider>
                    </form>
                  </>
                )
              }}
            </WithSmartFilters>
          )}
        </WithDisableFiltersBox>
      )
    } else {
      return (
        <>
          <p
            style={{
              padding: '2rem',
              fontSize: '1.7rem',
              margin: '0',
            }}
          >
            Find Your Samples
          </p>
          <form>
            <Grid
              container
              direction="row"
              alignItems="flex-end"
              spacing={2}
              justify="center"
              ref={findyourSamplesCardRef}
            >
              <Grid item>
                <ComponentMask
                  loaded={profileContext.minimumProfileLoaded}
                  childRenderHeight={'50px'}
                  childRenderWidth={
                    findyourSamplesCardRef.current
                      ? `${findyourSamplesCardRef.current.offsetWidth}px`
                      : undefined
                  }
                  loaderType="line"
                ></ComponentMask>
              </Grid>
            </Grid>
            <ThemeProvider theme={theme}>
              <div style={{ marginTop: '2rem' }}>
                <AccordionActions style={{ justifyContent: 'center' }}>
                  <Grid
                    container
                    item
                    direction="row"
                    spacing={3}
                    xs={12}
                    justify={'center'}
                  >
                    <Grid item>
                      <SearchButton
                        id="find_your_reports-search_button"
                        disabled
                      />
                    </Grid>
                    <Grid item>
                      <ResetButton
                        id="find_your_reports-reset_button"
                        disabled
                      />
                    </Grid>
                  </Grid>
                </AccordionActions>
              </div>
            </ThemeProvider>
          </form>
        </>
      )
    }
  }, [
    layoutRendered,
    profileContext.dependentData.filters.initialFilterOptionsLoaded,
    profileContext.dependentData.filters.minimumFilterOptionsLoaded,
    profileContext.minimumProfileLoaded,
    selectedSamplesSnapshotDate,
  ])

  const alertBanner = useMemo(() => {
    return (
      <FinancialAlertBanner
        customers={profileContext.profile.customers}
        plants={[]}
      />
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileContext.profile.customers?.length])

  useEffect(() => {
    if (financialHoldSamples.length > 0) {
      setOpenFinancialHoldModal(true)
    }
  }, [financialHoldSamples])

  return (
    <>
      <FinancialHoldModal
        open={openFinancialHoldModal}
        onClose={() => setOpenFinancialHoldModal(false)}
      />
      <FinancialHoldBanner showBanner={financialHoldSamples.length > 0} />
      {alertBanner}
      <SEO title="Dashboard" />
      <SampleIssueBanner customers={customers} />

      {offline ? (
        <OfflineBanner />
      ) : (
        <>
          {customers?.length === 1 && customers[0].dBLogo ? (
            <Box style={{ textAlign: 'right' }} mb={4}>
              <img
                id="logo_img"
                style={{ width: 290, height: 80 }}
                src={`${process.env.REACT_APP_API_URL}/logo/${profileContext.minimumProfileLoaded &&
                    profileContext.profile.customers.length > 0
                    ? profileContext.profile?.customers[0].custID
                    : ''
                  }`}
              />
            </Box>
          ) : null}
          <Box></Box>
          <Box mt={3} mb={4} mr={1} ml={1}>
            <Grid
              container
              direction="row"
              spacing={0}
              xs={12}
              justify="center"
            >
              <Grid
                item
                container
                justify="center"
                xs={12}
                sm={10}
                direction="column"
                alignItems="center"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0)',
                  borderRadius: '10px',
                  boxShadow: '0 5px 20px rgba(100, 0, 0, 0.075)',
                }}
              >
                <ViewYourReportsBox />
                <hr style={{ color: 'gray', height: '10px', width: '100%' }} />
                {filterComponent}
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Grid
        item
        container
        direction="row"
        spacing={2}
        style={{
          marginTop: '25px',
        }}
      >
        {!samplesSnapshotLoaded || !profileContext.minimumProfileLoaded ? (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Loader />
          </Grid>
        ) : samplesSnapshot.length > 0 ? (
          <>
            <Box mt={5} mb={2}>
              <Typography variant="h3">Machine/Lube Condition</Typography>
            </Box>
            <MachineCardSlider
              sampleSnapShots={samplesSnapshot}
              startDate={startDate}
              startDateParam={startDateParam}
              endDateParam={endDateParam}
              classes={classes}
            />
          </>
        ) : (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <em>No samples were found for the specified month.</em>
          </Grid>
        )}
      </Grid>

      <Grid
        container
        direction="row"
        alignItems="center"
        justify="center"
        style={{ marginTop: '35px' }}
        spacing={2}
      >
        <Grid item xs={12} sm={6} md={4} lg={4}>
          {!samplesComplianceLoaded ? (
            <Loader />
          ) : (
            <WrappedResponsiveLineChart
              loaded={samplesCompliance && samplesComplianceLoaded}
              classes={classes}
              title={'Sample Count'}
              lineData={samplesCompliance.map((x, index) => ({
                id: x.id,
                unit: `U-${x.id}`,
                points: x.data,
                color: index === 0 ? '#A6CEE3' : '#1F78B4',
                dashType: index === 0 ? 'dash' : 'solid',
              }))}
            >
              <SampleCountIcon
                style={{ display: 'inline-block', float: 'right' }}
              />
            </WrappedResponsiveLineChart>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          {!exceptionsLoaded ? (
            <Loader />
          ) : (
            <WrappedResponsiveLineChart
              loaded={exceptions && exceptionsLoaded}
              classes={classes}
              title={'Exceptions'}
              lineData={exceptions.map((x, index) => {
                return {
                  id: x.id,
                  unit: `U-${x.id}`,
                  points: x.data,
                  color: index === 0 ? '#1F78B4' : '#FF7F0E',
                }
              })}
            >
              <ExceptionsIcon
                style={{ display: 'inline-block', float: 'right' }}
              />
            </WrappedResponsiveLineChart>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={4}>
          {!exceptionsSummaryLoaded ? (
            <Loader />
          ) : (
            <WrappedResponsiveBarChart
              title={'Exceptions in last 90 days'}
              classes={classes}
              loaded={exceptionsSummary && exceptionsSummaryLoaded}
              data={exceptionsSummary}
              layout="horizontal"
              margin={{ top: 0, right: 15, bottom: 0, left: 100 }}
              colors={{ scheme: 'set2' }}
              keys={[
                'Degradation',
                'Ferrous Wear',
                'Particle Count',
                'Viscosity',
                'Water',
                'Wear Metals',
                'Other',
              ]}
              indexBy="id"
              padding={0.3}
              borderColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              axisTop={null}
              axisRight={null}
              axisBottom={null}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: '',
                legendPosition: 'middle',
                legendOffset: -40,
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={{
                from: 'color',
                modifiers: [['darker', 1.6]],
              }}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
            >
              <Exceptions90DaysIcon
                style={{ display: 'inline-block', float: 'right' }}
              />
            </WrappedResponsiveBarChart>
          )}
        </Grid>
      </Grid>

      <TableWrapper
        headerTitle="Recurring Exceptions"
        minimizable={true}
        headerActions={[
          <Link to={'/reports/recurring-exceptions'}>View More</Link>,
        ]}
        tableLoaded={recurringExceptionsLoaded}
      >
        {recurringExceptions && (
          <RecurringExceptionsTable
            recurringExceptions={recurringExceptions}
            showDivisions={recurringExceptions.some(d => d.divisionName)}
            showCustomers
            loaded={recurringExceptionsLoaded}
          />
        )}
      </TableWrapper>

      <TableWrapper
        headerTitle="Last 100 Samples"
        minimizable={true}
        headerActions={[
          <Link
            state={{ filterOptionsForLastHundredSamples }}
            to={`/reports/last-sample`}
          >
            View More
          </Link>,
        ]}
        tableLoaded={lastSamplesLoaded}
      >
        {lastSamples && (
          <LastHundredSamplesTable
            samples={lastSamples}
            loaded={lastSamplesLoaded}
            machineTypeOptions={state.machineTypeOptions}
          />
        )}
      </TableWrapper>
    </>
  )
}

const Page = ({ classes }) => {
  return (
    <AppLayout tab="dashboard">
      <Dashboard classes={classes} />
    </AppLayout>
  )
}

export default withStyles(styles)(Page)
