import styled from '@emotion/styled'
import { IconButton, Modal, makeStyles } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import APIService from 'services/api-service'
import { TAPICustomer, TNoSalesOrderQuery, TNoSalesOrderSample } from 'types/api'
import { CancellablePromise } from 'utils/CancellablePromise'
import CloseIcon from '@mui/icons-material/Close';

interface TProps {
    open: boolean;
    onClose: () => void;
}

const FinancialHoldModal: React.FC<TProps> = (props: TProps) => {
    return (
        <Modal
            open={props.open}
            onClose={props.onClose}
        >
            <StyledContainer>
                <CloseButton onClick={props.onClose}>
                    <CloseIcon sx={{color: '#61fcff'}}/>
                </CloseButton>
                <Text>
                    You have samples that need ATTENTION. Contact Customer Service Team at (216) 251-2510.
                </Text>
            </StyledContainer>
        </Modal>
    )
}

export default FinancialHoldModal

const CloseButton = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
`;

const Text = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`

const StyledContainer = styled.div`
    width: 50%;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgb(200, 40, 40); */
    background-color: #B71C1C;
    color: #61fcff;
    border-radius: 4px;
    font-size: 15pt;
`